import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import changePage from '../functions/changePage';
import { dispatcher } from '../redux/redux';
// import setSpacesInText from '../functions/setSpacesInText';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { soonInfo, waitMode } = this.props;
        const footer = this.props.footer || soonInfo?.components?.footer;

        return (
            <>
                <div ref={this.parent} className="footer">
                    <div className="footer__inner">
                        {!waitMode && (
                            <div className="footer__nav">
                                {(footer?.menu || []).map((link) => {
                                    const Tag = link.url ? 'a' : 'div';
                                    const tagProps = link.url
                                        ? { href: link.url, target: '_blank' }
                                        : link.key === 'feedback'
                                        ? {
                                              onClick: () => {
                                                  dispatcher({
                                                      type: 'ancorToFaqForm',
                                                      data: true,
                                                  }).then(() => {
                                                      changePage({ href: 'faq' });
                                                  });
                                              },
                                          }
                                        : {};

                                    return (
                                        <div className="footer__navItem" key={link.title}>
                                            <Tag {...tagProps} className="footer__navLink">
                                                {link.title}
                                            </Tag>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <p className="footer__description">
                            Срок проведения акции с 01.05.2024 по 31.07.2024. Информацию об
                            организаторе акции, правилах ее проведения, количестве призов, сроках,
                            месте и порядке их получения см. на dobrycola-promo.ru. Внешний вид
                            товаров может отличаться от их изображения в рекламных материалах.
                            “Добрый” является зарегистрированным товарным знаком. ⓒ 2024. АО
                            “Мултон”. Все права защищены. * Подробности смотрите в правилах акции.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Footer);

Footer.propTypes = {
    device: PropTypes.string,
    waitMode: PropTypes.string,
    soonInfo: PropTypes.object,
    footer: PropTypes.object,
};
