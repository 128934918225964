import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import File from '../../classes/File';

import getHeaders from '../../functions/getHeaders';

import Button from '../../components/Button.jsx';
import Select from '../../components/Select.jsx';
import Field from '../../components/Field.jsx';
import Upload from '../../components/Upload.jsx';
import Popup from '../../components/Popup.jsx';
import requestSuccess from '../../functions/requestSuccess';

class Form extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendForm = this.sendForm.bind(this);

        this.parent = React.createRef();
    }

    fieldsOrder = ['date', 'time', 'sum', 'fn', 'fp', 'fd', 'file'];

    fields = {
        date: {
            type: 'selects',
            selects: ['day', 'month'],
            support: 'Дата на чеке',
        },
        time: {
            type: 'selects',
            selects: ['hour', 'minute'],
            support: 'время на чеке',
        },
        sum: {
            support: 'Сумма на чеке',
        },
        fn: {
            support: 'ФН',
        },
        fp: {
            support: 'ФП',
        },
        fd: {
            support: 'ФД',
        },
        file: {
            type: 'file',
        },
    };

    selects = {
        day: 'День',
        month: 'Месяц',
        hour: 'Часы',
        minute: 'Минуты',
    };

    getFormatedNumber(num) {
        return +parseInt(num, 10) < 10 ? `0${parseInt(num, 10)}` : `${parseInt(num, 10)}`;
    }

    getSelectList({ name }) {
        let len;
        const otherItems = [];

        if (name === 'day') {
            len = 31;
        }

        if (name === 'month') {
            len = 12;
        }

        if (name === 'hour') {
            len = 23;
            otherItems.push(0);
        }

        if (name === 'minute') {
            len = 59;
            otherItems.push(0);
        }

        return otherItems
            .concat(...new Array(len).fill({}).map((item, key) => key + 1))
            .map((item) => ({
                key: this.getFormatedNumber(item),
                content: this.getFormatedNumber(item),
            }));
    }

    uploadFiles({ target }) {
        this.formData = new FormData();

        this.handlerFile
            .uploadFiles({
                target,
                getName: (key) => `${key}-${new Date().getTime()}`,
                formData: this.formData,
            })
            .then(({ resultFiles }) => {
                this.setState((state) => {
                    const newState = { ...state };

                    newState.files = resultFiles;

                    return newState;
                });
            });
    }

    sendForm() {
        const { fields } = this.state;
        const { setBlock } = this.props;
        const form = {
            fn: fields?.fn || null,
            fp: fields?.fp || null,
            fd: fields?.fd || null,
            sum: fields?.sum || null,
            day: fields?.day || null,
            month: fields?.month || null,
            hour: fields?.hour || null,
            min: fields?.minute || null,
        };

        Object.keys(form).forEach((key) => {
            if (form[key]) {
                this.formData.set(key, form[key]);
            }
        });

        this.handlerLoading('send', { error: null }).then(() => {
            axios
                .post(`${process.env.REACT_APP_API}/api/CheckRegistration`, this.formData, {
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        this.handlerLoading(null);

                        requestSuccess(res);

                        const { result } = res.data;

                        if (result === 'OK') {
                            setBlock({ name: 'success' });

                            document.dispatchEvent(new CustomEvent('updateProfile'));
                        }
                    },
                    (err) => {
                        this.handlerLoading(null);

                        if (err?.response) {
                            const { result, errorText } = err.response.data;

                            if (result === 'ERROR') {
                                this.setState({ error: errorText });
                            }
                        }
                    },
                );
        });
    }

    formData = new FormData();

    handlerFile = new File({});

    componentDidMount() {
        const { scanData } = this.props;

        if (scanData) {
            this.setState({ fields: scanData }, () => {
                this.sendForm();
            });
        }
    }

    render() {
        const { fields, files, error, loadingKey } = this.state;

        return (
            <>
                <div className="popup__head">
                    <div className="popup__headTitle">регистрация чека</div>
                </div>
                <div className="popup__content">
                    <div className="popup__form">
                        <div className="popup__formFields">
                            {this.fieldsOrder.map((name) => {
                                const fieldInfo = this.fields[name];
                                const { support, type } = fieldInfo;

                                return (
                                    <div
                                        className={`popup__formField ${type ? `_${type}` : ''}`}
                                        key={name}
                                    >
                                        {support && (
                                            <div className="popup__formFieldSupport">{support}</div>
                                        )}
                                        <div className="popup__formFieldContent">
                                            {type === 'selects' && (
                                                <>
                                                    {fieldInfo.selects.map((select) => (
                                                        <div
                                                            className="popup__formFieldSelect"
                                                            key={select}
                                                        >
                                                            <Select
                                                                value={fields?.[select]}
                                                                className="_main"
                                                                support={this.selects[select]}
                                                                list={this.getSelectList({
                                                                    name: select,
                                                                })}
                                                                name={select}
                                                                onChange={this.handlerField}
                                                            />
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                            {type === 'file' && (
                                                <>
                                                    <Upload
                                                        className="_main"
                                                        onChange={this.uploadFiles}
                                                        multiple={true}
                                                        files={files}
                                                    >
                                                        Приложите одно или несколько фото чека
                                                        <br className="_desktopMedia" /> с
                                                        фискальными данными
                                                    </Upload>
                                                </>
                                            )}
                                            {!type && (
                                                <>
                                                    <Field
                                                        value={fields?.[name]}
                                                        name={name}
                                                        onChange={this.handlerField}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="popup__foot">
                    {this.renderError({ error })}
                    <div className="popup__buttons">
                        <div className="popup__button _wide">
                            <Button
                                className="_green _mediumSize"
                                onClick={this.sendForm}
                                loader={loadingKey === 'send'}
                            >
                                ОТПРАВИТЬ ЧЕК НА ПРОВЕРКУ
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Form);

Form.propTypes = {
    device: PropTypes.string,
    scanData: PropTypes.object,
    setBlock: PropTypes.func,
    setUpdateKey: PropTypes.func,
};
