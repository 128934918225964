import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { setBlock, qrType, uploadQr, loadingKey } = this.props;

        return (
            <>
                <div className="popup__head">
                    <div className="popup__headTitle">регистрация чека</div>
                </div>
                <div className="popup__content">
                    <div className="popup__qrError">
                        <div className="popup__qrErrorTitle">Ошибка:</div>
                        <div className="popup__qrErrorContent">
                            <div className="error _mediumSize">НЕВЕРНЫЙ QR-код</div>
                        </div>
                        {qrType === 'scan' && (
                            <div className="popup__qrErrorButton">
                                <Button
                                    className="_green _mediumSize"
                                    onClick={() => {
                                        setBlock({ name: 'scan' });
                                    }}
                                >
                                    ПОПРОБОВАТЬ ДРУГОЙ ЧЕК
                                </Button>
                            </div>
                        )}
                        {qrType === 'file' && (
                            <label className="popup__qrErrorButton">
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={uploadQr}
                                    disabled={!!loadingKey}
                                />
                                <Button
                                    className="_green _mediumSize"
                                    loader={loadingKey === 'file'}
                                >
                                    ПОПРОБОВАТЬ ДРУГОЙ ЧЕК
                                </Button>
                            </label>
                        )}
                    </div>
                </div>
                <div className="popup__foot">
                    <div className="popup__buttons">
                        <div className="popup__button _height">
                            <Button
                                className="_darkOrange _mediumSize"
                                onClick={() => {
                                    setBlock({ name: 'form' });
                                }}
                            >
                                ввести данные
                                <br className="_desktopMedia" /> чека вручную
                            </Button>
                        </div>
                        <label className="popup__button _height">
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={uploadQr}
                                disabled={!!loadingKey}
                            />
                            <Button
                                className="_darkOrange _mediumSize"
                                loader={loadingKey === 'file'}
                            >
                                загрузить фото
                                <br className="_desktopMedia" /> qr-кода с чека
                            </Button>
                        </label>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Error);

Error.propTypes = {
    device: PropTypes.string,
    setBlock: PropTypes.func,
    qrType: PropTypes.string,
    uploadQr: PropTypes.func,
    loadingKey: PropTypes.string,
};
