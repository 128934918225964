import axios from 'axios';
// import getHeaders from './getHeaders';
import requestSuccess from './requestSuccess';

export default function getContent({ name }) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/json/ms.2024/${name}/`, {
                headers: {}
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    resolve(res.data);
                },
                () => {
                    reject();
                },
            );
    });
}
