import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';

class WaitAbout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return (
            <>
                <div ref={this.parent} className="waitAbout">
                    <div className="waitAbout__inner">
                        <div className="waitAbout__content">
                            <p>
                                Новогодняя акция «Фабрика добрых чудес и подарков» завершена. Если у
                                Вас остались вопросы по прошедшей акции, в частности
                                по&nbsp;получению призов, обращайтесь на почту:
                            </p>
                            <p>
                                <a href="#">support@dobrycola-promo.ru</a>
                            </p>
                        </div>
                        <a className="waitAbout__button" href="#" target="_blank">
                            <Button className="_white">Правила акции</Button>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(WaitAbout);

WaitAbout.propTypes = {
    device: PropTypes.string,
};
