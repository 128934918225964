import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../scss/main.scss';

import { dispatcher } from '../redux/redux';

import getCurrentPage from '../functions/getCurrentPage';
import changePage from '../functions/changePage';

import CustomHead from '../components/CustomHead.jsx';
import Pages from '../components/Pages.jsx';
// import TopBar from '../components/TopBar.jsx';
import MobileMenu from '../components/MobileMenu.jsx';
import Cookies from '../components/Cookies.jsx';

// import Index from './Index.jsx';
import About from './About.jsx';
import Faq from './Faq.jsx';
import Anket from './Anket.jsx';
import PrizeAnket from './PrizeAnket.jsx';
import Act from './Act.jsx';
import Profile from './Profile.jsx';
import Login from './Login.jsx';
import Registration from './Registration.jsx';
import Animate from '../components/Animate.jsx';
import Cheques from './Cheques.jsx';
import Wait from './Wait.jsx';
import saveJWT from '../functions/saveJWT';
import { getCookie, setCookie } from '../functions/handlerCookies';
import checkAuth from '../functions/checkAuth';
import PromoPopup from '../components/PromoPopup.jsx';
import GamePopup from '../components/GamePopup.jsx';
import EmailPopup from '../components/EmailPopup.jsx';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerAncor = this.handlerAncor.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);

        this.parent = React.createRef();
    }

    waitMode = true;

    pages = {
        index: {
            render() {
                return (
                    <>
                        {/* <Index /> */}
                    </>
                );
            },
        },
        about: {
            render() {
                return (
                    <>
                        <About />
                    </>
                );
            },
        },
        faq: {
            render() {
                return (
                    <>
                        <Faq />
                    </>
                );
            },
        },
        anket: {
            render() {
                return (
                    <>
                        <Anket />
                    </>
                );
            },
        },
        prizeAnket: {
            render() {
                return (
                    <>
                        <PrizeAnket />
                    </>
                );
            },
        },
        act: {
            render() {
                return (
                    <>
                        <Act />
                    </>
                );
            },
        },
        profile: {
            render() {
                return (
                    <>
                        <Profile />
                    </>
                );
            },
        },
        login: {
            render() {
                return (
                    <>
                        <Login />
                    </>
                );
            },
        },
        registration: {
            render() {
                return (
                    <>
                        <Registration />
                    </>
                );
            },
        },
        cheques: {
            render() {
                return (
                    <>
                        <Cheques />
                    </>
                );
            },
        },
        404: {
            render() {
                return <>404</>;
            },
        },
    };

    visibilityDocChange() {
        if (!document.hidden) {
            checkAuth();
        }
    }

    handlerAncor({ detail: { name, changeIsHard } }) {
        const { storePages } = this.props;
        const currentPage = getCurrentPage({ pages: storePages, filter: (page) => !page.level });

        if (currentPage !== 'index') {
            dispatcher({ type: 'indexAncor', data: name });
            changePage({ href: '', changeIsHard });
            dispatcher({ type: 'mobileMenuShow', data: false });
        } else {
            document.dispatchEvent(new CustomEvent('indexScroll', { detail: { name } }));
        }
    }

    componentDidMount() {
        document.addEventListener('indexAncor', this.handlerAncor);
        document.addEventListener('visibilitychange', this.visibilityDocChange);

        const rootJWT = document.querySelector('#root').getAttribute('data-jwt');

        if (rootJWT) {
            setCookie(process.env.REACT_APP_HASH, rootJWT);
        }

        saveJWT(getCookie(process.env.REACT_APP_HASH));

        checkAuth();

        window.getJWT = () => getCookie(process.env.REACT_APP_HASH);
        window.saveJWT = saveJWT;
        window.apiUrl = process.env.REACT_APP_API;
    }

    render() {
        const { windowIsReady, device, storePages, promoPopup, gamePopup, emailPopup } = this.props;
        const currentPage = getCurrentPage({ pages: storePages, filter: (page) => page.isPopup });

        return (
            <>
                <div
                    ref={this.parent}
                    className={`body__contentInner ${this.waitMode ? '_waitMode' : ''}`}
                >
                    <CustomHead
                        title="Добрый"
                        description="Промо"
                        link={`${process.env.REACT_APP_DOMEN}/`}
                    />
                    {this.waitMode ? (
                        <>
                            <div className="body__page _show">
                                <Wait />
                            </div>
                        </>
                    ) : (
                        <>
                            {device === 'mobile' && (
                                <>
                                    <MobileMenu />
                                </>
                            )}
                            {/* <TopBar /> */}
                            <Cookies />
                            <Animate
                                className="body__popupBack"
                                isShow={
                                    !!currentPage ||
                                    promoPopup?.isShow ||
                                    gamePopup?.isShow ||
                                    emailPopup?.isShow
                                }
                            />
                            <Animate className="body__popup _promo" isShow={promoPopup?.isShow}>
                                <PromoPopup />
                            </Animate>
                            <Animate className="body__popup _game" isShow={gamePopup?.isShow}>
                                <GamePopup />
                            </Animate>
                            <Animate className="body__popup _email" isShow={emailPopup?.isShow}>
                                <EmailPopup />
                            </Animate>

                            {windowIsReady && (
                                <Pages
                                    className="body__pages"
                                    classNamePage="body__page"
                                    filter={(page) => !page.level}
                                    pages={this.pages}
                                    context={this}
                                />
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        storePages: state.pages,
        loadingImage: state.loadingImage,
        windowIsReady: state.windowIsReady,
        user: state.user,
        promoPopup: state.promoPopup,
        gamePopup: state.gamePopup,
        emailPopup: state.emailPopup,
    };
}

export default connect(mapStateToProps)(App);

App.propTypes = {
    device: PropTypes.string,
    windowIsReady: PropTypes.bool,
    storePages: PropTypes.object,
    loadingImage: PropTypes.string,
    user: PropTypes.object,
    promoPopup: PropTypes.object,
    gamePopup: PropTypes.object,
    emailPopup: PropTypes.object,
};
