import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import jsQR from '../../plugins/QR';

import Loader from '../../components/Loader.jsx';
import Animate from '../../components/Animate.jsx';

class Scan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setFrame = this.setFrame.bind(this);
        this.videoStop = this.videoStop.bind(this);

        this.parent = React.createRef();
    }

    complete({ data }) {
        const { setBlock, parseQr } = this.props;

        if (data) {
            const scanData = parseQr({ data });

            setBlock({ name: 'form', scanData });
        } else {
            setBlock({ name: 'error', qrType: 'scan' });
        }
    }

    video = null;

    flagTick = false;

    setFrame() {
        if (this.video.readyState === this.video.HAVE_ENOUGH_DATA && this.flagTick === true) {
            const canvas = this.parent.current.querySelector('canvas');
            const canvasContext = canvas.getContext('2d');

            canvas.hidden = false;

            canvas.height = this.video.videoHeight;
            canvas.width = this.video.videoWidth;

            canvasContext.drawImage(this.video, 0, 0, canvas.width, canvas.height);

            const imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
            const qrDecoded = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: 'dontInvert',
            });

            if (qrDecoded) {
                if (qrDecoded.data) {
                    if (!this.isComplete) {
                        this.isComplete = true;

                        this.complete({ data: qrDecoded.data, type: 'qr-scan' });

                        setTimeout(() => {
                            this.videoStop();
                        }, 300);
                    }
                } else {
                    this.setCurrentStep('error');
                }
            }
        }

        requestAnimationFrame(this.setFrame);
    }

    videoStop() {
        if (this.parent.current) {
            const canvas = this.parent.current.querySelector('canvas');

            if (canvas) {
                canvas.hidden = true;
            }

            this.setState({ isInit: false });
        }

        if (this.video?.srcObject) {
            this.video.srcObject.getTracks().forEach((track) => track.stop());
            this.flagTick = false;

            this.isComplete = false;
        }
    }

    videoStart() {
        this.video = document.createElement('video');
        this.flagTick = true;

        navigator.mediaDevices
            .getUserMedia({ video: { facingMode: 'environment' } })
            .then((stream) => {
                this.video.srcObject = stream;
                this.video.setAttribute('playsinline', true);
                this.video.play();

                this.setState({ isInit: true });

                requestAnimationFrame(this.setFrame);
            });
    }

    componentDidMount() {
        this.videoStart();
    }

    componentWillUnmount() {
        this.videoStop();
    }

    render() {
        const { isInit } = this.state;

        return (
            <>
                <div className="popup__head">
                    <div className="popup__headTitle">регистрация чека</div>
                </div>
                <div ref={this.parent} className="popup__content">
                    <div className="popup__qrArea">
                        <Animate className="popup__qrAreaLoader" isShow={!isInit}>
                            <div className="popup__qrAreaLoaderItem">
                                <Loader />
                            </div>
                        </Animate>
                        <canvas hidden />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Scan);

Scan.propTypes = {
    device: PropTypes.string,
    setBlock: PropTypes.func,
    parseQr: PropTypes.func,
};
