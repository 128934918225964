import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '../../components/Button.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import Animate from '../../components/Animate.jsx';
import FormPage from '../../components/FormPage.jsx';
import handlerLoading from '../../functions/handlerLoading';
import getHeaders from '../../functions/getHeaders';
import requestSuccess from '../../functions/requestSuccess';

class FaqForm extends FormPage {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: [],
        };

        this.sendForm = this.sendForm.bind(this);

        this.parent = React.createRef();
    }

    fieldsOrder = ['message', 'email', 'name', 'noAnswer', 'policy'];

    fields = {
        message: {
            support: 'ВАШ ВОПРОС',
            type: 'area',
        },
        email: {
            support: 'E-Mail для ответа',
        },
        name: {
            support: 'КАК К ВАМ ОБРАЩАТЬСЯ',
        },
        noAnswer: {
            type: 'checkbox',
            support: 'ОТВЕТА НА МОЙ ВОПРОС НЕТ В СПИСКЕ ВЫШЕ',
        },
        policy: {
            type: 'checkbox',
            support: (
                <>
                    Я соглашаюсь с <a href="#">политикой конфиденциальности</a> и{' '}
                    <a href="#">условиями обработки персональных данных</a>
                </>
            ),
        },
    };

    sendForm() {
        const { fields } = this.state;

        handlerLoading.call(this, true, { error: null }).then(() => {
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/SendQuestion`,
                    {
                        email: fields.email,
                        name: fields.name,
                        question: fields.message,
                        notFoundQuestion: !!fields.noAnswer,
                        agreement: !!fields.policy,
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        requestSuccess(res);

                        handlerLoading.call(this, null);

                        const { result } = res.data;

                        if (result === 'OK') {
                            this.setSuccess();
                        }
                    },
                    (err) => {
                        const { errorText } = err.response.data;

                        handlerLoading.call(this, null, { error: errorText });
                    },
                );
        });
    }

    render() {
        const {
            error,
            isSuccess = false,
            isCompleteSuccess,
            formHeight = 0,
            loadingKey,
            fields,
        } = this.state;

        return (
            <>
                <div ref={this.parent} className="faqForm" id="faqForm">
                    <div className="faqForm__inner">
                        <div className="faqForm__head">
                            <div className="innerPageHead">
                                <p className="innerPageHead__description">
                                    Если Вы не нашли ответ на&nbsp;свой вопрос,
                                    <br className="_desktopMedia" /> свяжитесь с&nbsp;нами:
                                </p>
                            </div>
                        </div>
                        <div className="faqForm__content">
                            <div className="faqForm__contentInner">
                                <div
                                    className={`form ${isCompleteSuccess ? '_success' : ''}`}
                                    style={isSuccess ? { height: `${formHeight}px` } : {}}
                                >
                                    <Animate className="form__success" isShow={isSuccess}>
                                        <div className="form__successContent">
                                            <span>Спасибо, {fields?.name}!</span>
                                            Сообщение отправлено, мы свяжемся с&nbsp;вами
                                            в&nbsp;ближайшее время.
                                        </div>
                                    </Animate>
                                    <div className="form__inner">
                                        <div className="form__fields">
                                            {this.fieldsOrder.map((name) =>
                                                this.renderField({ name }),
                                            )}
                                        </div>
                                        <AnimateChange
                                            className="form__error _center"
                                            renderKey={error}
                                            paramsParent={{
                                                width: document.querySelector('.form__inner')
                                                    ?.offsetWidth,
                                            }}
                                            itemParams={['width']}
                                        >
                                            {error ? <div className="error">{error}</div> : null}
                                        </AnimateChange>
                                        <div className="form__button">
                                            <Button
                                                className="_green _bigSize"
                                                onClick={this.sendForm}
                                                loader={!!loadingKey}
                                            >
                                                ОТПРАВИТЬ
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(FaqForm);

FaqForm.propTypes = {
    device: PropTypes.string,
};
