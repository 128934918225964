import React from 'react';
import PropTypes from 'prop-types';

import AuthPage from './AuthPage.jsx';
import getContent from '../functions/getContent';
import setMainContent from '../functions/setMainContent';

class Page extends AuthPage {
    constructor(props) {
        super(props);
        this.state = {};

        this.saveScroll = this.saveScroll.bind(this);
        this.updateContent = this.updateContent.bind(this);

        this.parent = React.createRef();
    }

    getLocalName() {
        return `scroll-${this.pageName}`;
    }

    getScrollPage() {
        return this.parent.current.closest('.body__page');
    }

    saveScroll() {
        const scrollPage = this.getScrollPage ? this.getScrollPage() : this.props.scrollPage;

        if (scrollPage) {
            const { scrollTop } = scrollPage;

            if (this.pageName) {
                localStorage.setItem(this.getLocalName(), scrollTop);
            }
        }
    }

    checkReadyScrollPage() {
        const scrollPage = this.getScrollPage ? this.getScrollPage() : this.props.scrollPage;

        if (
            scrollPage &&
            !this.isInitScrollPage &&
            (this.state.isReadyForScroll || this.state.isReadyForScroll === undefined)
        ) {
            this.isInitScrollPage = true;

            if (this.pageName) {
                const scrollTop = +localStorage.getItem(this.getLocalName());

                if (scrollTop) {
                    scrollPage.scrollTo({ top: scrollTop });

                    this.setState({ isInit: true }, () => {
                        scrollPage.scrollTo({ top: scrollTop });
                    });
                } else {
                    this.setState({ isInit: true });
                }
            } else {
                this.setState({ isInit: true });
            }

            scrollPage.addEventListener('scroll', this.saveScroll);

            if (this.initScroll) {
                this.initScroll();
            }
        }
    }

    getContent() {
        getContent({ name: this.contentName }).then(
            (res) => {
                const { components, extra } = res;

                setMainContent({ components, extra });

                this.setState({ components }, () => {
                    if (this.getContentCallback) {
                        this.getContentCallback();
                    }
                });
            },
            () => {},
        );
    }

    updateContent({ detail: { name } }) {
        if (name === this.pageName) {
            this.getContent();
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.checkReadyScrollPage();

        this.setState({ isUpdate: true });

        // this.getContent();

        document.addEventListener('updateContent', this.updateContent);
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        this.checkReadyScrollPage();
    }

    componentWillUnmount() {
        const scrollPage = this.getScrollPage ? this.getScrollPage() : this.props.scrollPage;

        if (scrollPage) {
            scrollPage.removeEventListener('scroll', this.saveScroll);
        }

        if (this.pageName) {
            localStorage.removeItem(this.getLocalName());
        }

        document.removeEventListener('updateContent', this.updateContent);
    }
}

export default Page;

Page.propTypes = {
    scrollPage: PropTypes.object,
    storePages: PropTypes.object,
};
